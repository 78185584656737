<template>
  <div class="loaduser section" style="height: 100vh;">
    <div class="box">
      <p class="has-text-centered ">
        <span class="icon"><i class="fas fa-circle-notch fa-spin" /></span> <span>Loading User...</span>
      </p>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
export default {
  name: 'LoadUser',
  mounted () {
    this.loadUser()
  },
  methods: {
    loadUser () {
      request('get', 'users/me', null)
        .then((response) => {
          const data = response.data
          // console.log(data)
          if (data.error) {
            // console.log(data.error)
            alert(data.error)
            return
          }

          this.$store.commit('SET_USER', data.user)
          this.$store.commit('SET_TOKEN', data.user.token)
          if (window.localStorage) {
            window.localStorage.setItem('user', JSON.stringify(data.user))
            window.localStorage.setItem('token', data.user.token)
          }
          // console.log(this.$route.params.route)
          if (data.user.school && !data.user.school.teacher) {
            window.location.replace('https://www.edshed.com/menu')
          }
          if (this.$route.params.route) {
            this.$router.replace({ path: this.$route.params.route.path, query: this.$route.params.route.query })
          } else {
            this.$router.replace('/')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push({ name: 'Logout' })
          }
        })
    }
  }
}
</script>
<style scoped>
  .icon {
    vertical-align: bottom;
  }
</style>
